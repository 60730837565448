import React from 'react'
import { MDBMask, MDBView, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import BackgroundImage from 'gatsby-background-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'



class Hero extends React.Component {
  render() {
/*     const sources = ((this.props.type === 'news' || this.props.type === 'blog') && (this.props.ctaslug === null || this.props.ctaslug === undefined)) ? 
    [
      {aspectRatio: 1, src: '', srcSet: ''},
      {...this.props.image, media: `(min-width: 480px)`}, 
    ] 
    :
    this.props.image */

    return (
      <BackgroundImage
        Tag="section"
        className={this.props.class}
        fluid={this.props.image}
        alt={this.props.alttext}
      >
        <MDBView>
          <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center gradient' } >
          <MDBContainer className="h-100">
              <MDBRow className="align-items-center h-100">
                <MDBCol>
              {this.props.ctaslug && (
                      <>
                        {this.props.ctaslug.substring(0,4) === 'http' ?
                          <a href={this.props.ctaslug} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-5 mt-lg-2">
                            <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                            {this.props.ctatext ? this.props.ctatext : 'Schedule a consultation'}
                          </a>
                        :
                          <Link to={this.props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                            <FontAwesomeIcon icon={faChevronRight} />{' '}
                            {this.props.ctatext != '' && this.props.ctatext != null ? this.props.ctatext : 'Read'}
                          </Link>
                        }
                      </>
              )}
              </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </BackgroundImage>
    )
  }
}

export default Hero
