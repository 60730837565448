import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Hero from '../components/heroBlogpage'
import SEO from '../components/seo'
import CTA from '../components/subscribeCta'
import FormMarketo from '../components/formMarketo'
import Card from '../components/cardRelatedBlog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarDays, faClock, faUser } from '@fortawesome/pro-solid-svg-icons'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import { StickyShareButtons } from 'sharethis-reactjs'
import CookiesParseHtml from "../components/cookiesParseHtml"


export default ({ data, location }) => {
  const post = data.markdownRemark
  const cta = data.cta
  const heroImage = data.heroImage
  const siteUrl = data.site.siteMetadata.siteUrl
  const pageUrl = siteUrl + post.fields.slug

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src }
        />
        <Hero
          class="intro-50"
          image={heroImage.childImageSharp.fluid}
          alt={post.frontmatter.alttext}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="blog"
        />

        <div className="d-none d-sm-block">
          <StickyShareButtons
            config={{
              alignment: 'right', // alignment of buttons (left, right)
              color: 'social', // set the color of buttons (social, white)
              enabled: true, // show/hide buttons (true, false)
              font_size: 16, // font size for the buttons
              hide_desktop: false, // hide buttons on desktop (true, false)
              labels: 'null', // button labels (cta, counts, null)
              language: 'en', // which language to use (see LANGUAGES)
              networks: [
                // which networks to include (see SHARING NETWORKS)
                'linkedin',
                'facebook',
                'twitter',                
                'whatsapp',
                'email',  
              ],
              padding: 12, // padding within buttons (INTEGER)
              show_mobile: false, // show/hide the buttons on mobile (true, false)
              show_toggle: false, // show/hide the toggle buttons (true, false)
              size: 48, // the size of each button (INTEGER)
              top: 85, // offset in pixels from the top of the page
              // OPTIONAL PARAMETERS//
              url: pageUrl, // (defaults to current url)
              description: post.frontmatter.description, // (defaults to og:description or twitter:description)
              title: post.frontmatter.title, // (defaults to og:title or twitter:title)
            }}
          />
        </div>


        <section className="bg-white pb-0">
          <MDBContainer>

            <MDBRow>
              <MDBCol lg="9" className="my-auto text-left" >
                <h1 className="font-alt font-w-700 title-xs-extra-large title-extra-large-1 pb-3" dangerouslySetInnerHTML={{ __html: post.frontmatter.title }} />
              </MDBCol>
            </MDBRow>
            
            <MDBRow className="justify-content-left font-alt font-w-400 text-gray-light text-medium letter-spacing-1 mt-3 mb-0">
              <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />{post.frontmatter.date}</MDBCol>
            
              {post.frontmatter.author && (
                <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faUser} className="mr-2" />{post.frontmatter.author}</MDBCol>
              )}                
              
              {post.frontmatter.readtime && (
                <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faClock} className="mr-2" />{post.frontmatter.readtime} read </MDBCol>
              )}
            </MDBRow>

          </MDBContainer>
        </section>

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="9">
                {post.frontmatter.openingpara && (
                  <MDBRow>
                    <MDBCol>
                      <div className="font-w-400 divlink text-opening-para"> {post.frontmatter.openingpara} </div>
                    </MDBCol>
                  </MDBRow>
                )}

                {typeof window !== 'undefined' && ! /Android|webOS|iPhone|iPod|BlackBerry|BB|IEMobile|Windows Phone|Silk|Opera Mini/i.test(navigator.userAgent ) && (
                  <>
                    {(!post.frontmatter.videourl && post.frontmatter.image) && (
                      <>
                      <MDBRow>
                        <MDBCol>
                          <Img fluid={ post.frontmatter.image.childImageSharp.fluid } className="rounded" alt={post.frontmatter.alttext} />
                          {post.frontmatter.caption && (
                            <div className="figure pt-3"> {' '} {post.frontmatter.caption}{' '} </div>
                          )}
                        </MDBCol>
                      </MDBRow>                                    
                      </>
                    )}
                  </>
                )}      

                {post.frontmatter.videourl && (
                  <MDBCol>
                    <div className="embed-responsive embed-responsive-16by9">
                      <CookiesParseHtml html={`<div><iframe className="embed-responsive-item rounded border" src="${post.frontmatter.videourl}?rel=0&modestbranding=1"></iframe></div>`} />
                      {/* <iframe className="embed-responsive-item rounded border" src={`${post.frontmatter.video}?rel=0&modestbranding=1`} ></iframe> */}
                    </div>
                  </MDBCol>
                )}  

                <MDBRow>
                  <MDBCol>
                    <div className="mt-lg-5 mt-0 richtext divlink blog-content" dangerouslySetInnerHTML={{ __html: post.html }} />
                    <p className="pt-lg-4 mt-md-4 text-large">
                      <Link to="/blog/" className="nav-link btn btn-mdb-color" > <FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Back to blogs{' '} </Link>
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol lg="3" md="12">
                <div className="RTP_RCMD2" data-rtp-template-id="template2"></div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <CTA
                title={cta.frontmatter.title}
                subtitle={cta.frontmatter.subtitle}                  
              />
              <FormMarketo formId="1668" />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {(post.frontmatter.helpfulresources && post.frontmatter.helpfulresources?.helpfulres?.length > 0) && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        helpfulres => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="12rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              readtime={helpfulres.readtime}
                              imageUrl={helpfulres.image.childImageSharp.fluid}
                              imageAltText={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "blog" } }
    ) {
      fields {
        slug 
      }
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        date(formatString: "MMMM DD, YYYY")
        author
        readtime
        description
        openingpara
        videourl
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        imageFeatured {
          childImageSharp {
            fluid(maxWidth: 900, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        caption                          
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            readtime
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 615, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
      }
    }
    cta: markdownRemark(
      frontmatter: { name: { eq: "blog-cta" } }
    ) {
      frontmatter {
        title
        subtitle
        linktext
        }
    } 
    heroImage: file(name: { eq: "blog-hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }   
  }
`
