import React, { useState, useEffect } from 'react'

const marketoScriptId = 'mktoForms';

export default function FormMarketo({ formId }) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    isLoaded &&
      window.MktoForms2.loadForm(
        '//go.unicomengineering.com',
        '221-VVO-216',
        formId
      );
      return () => {
        let scriptsuspects=document.getElementsByTagName("script");
        for (let i=scriptsuspects.length; i>=0; i--){
        if (scriptsuspects[i] && scriptsuspects[i].getAttribute("id")!==null 
            && scriptsuspects[i].getAttribute("id").indexOf("mktoForms") !== -1 ){
                scriptsuspects[i].parentNode.removeChild(scriptsuspects[i])
          }    
        }

        let iframesuspects=document.getElementsByTagName("iframe");
        for (let i=iframesuspects.length; i>=0; i--){
        if (iframesuspects[i] && iframesuspects[i].getAttribute("id")!==null 
            && iframesuspects[i].getAttribute("id").indexOf("MktoForms2XDIframe") !== -1 ){
                iframesuspects[i].parentNode.removeChild(iframesuspects[i])
          }    
        }
      }
  }, [isLoaded, formId]);

  const loadScript = () => {
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//go.unicomengineering.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function() {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);
  };

  return (
    <>
      <div>
        <form id={`mktoForm_${formId}`}></form>
      </div>
    </>
  )
}
